import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppState } from "../App";
import { signOut } from "firebase/auth";
import { auth } from "../Firebase/firebase";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from "firebase/firestore";

const NavBar = ({ name, email }) => {
  const App = useContext(AppState);
  const navigate = useNavigate();
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [banner, setBanner] = useState("");
  const [bannerLink, setBannerLink] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);

  useEffect(() => {
    try {
      const fetchBannerText = async () => {
        const db = getFirestore();
        const querySnapshot = await getDocs(collection(db, "banner"));
        if (!querySnapshot.empty) {
          const docSnap = querySnapshot.docs[0]; // This gets the first document in the collection
          setBanner(docSnap.data().text);
          setBannerLink(docSnap.data().link);
        } else {
          console.log("No Such Documents!!");
        }
      };

      fetchBannerText();
    } catch (error) {
      console.error("Error fetching banner text:", error);
    }
  }, []);

  useEffect(() => {}, [App.isLogin, App.adminUser]);

  const handleLogout = () => {
    signOut(auth);
    App.setUserName("");
    App.setIsLogin(false);
    App.setPurchased(null);
    navigate("/");
  };

  const handleBannerClose = () => {
    setIsBannerVisible(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleMobileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    handleMobileMenuClose();
  };

  // Close the mobile menu when the window is resized above a certain breakpoint
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {/* banner */}
      {isBannerVisible && banner && (
        <div className="flex items-center justify-between bg-indigo-600 px-2 py-2 text-white ">
          <div className="flex-grow container mx-auto">
            <p className="text-lg font-medium text-center">
              {/* <Link className='text-white hover:text-gray-300' to='/masterclasses'> */}
              <a
                href={bannerLink}
                target="_blank"
                className="inline-block underline font-medium hover:text-gray-200"
              >
                <u>{banner}</u>
              </a>
              {/* </Link> */}
            </p>
          </div>
          <button
            aria-label="Dismiss"
            className="shrink-0 rounded-lg bg-black/10 p-1 ml-4 transition hover:bg-black/20"
            onClick={handleBannerClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      )}
      {/* Navbar */}
      <nav>
        <div className="px-3 sm:px-2 header sticky z-10 top-0 text-lg items-center text-white font-lg border-b-2 border-gray-500">
          <div className="container mx-auto items-center flex justify-between">
            <div>
              <Link className="hover:text-indigo-400" to="/">
                <img
                  style={{ height: "6rem" }}
                  src="betaquant-logo.png"
                  alt=""
                />
              </Link>
            </div>
            <div className="flex flex-row justify-center items-center text-center">
              {/* Hamburger button */}

              <button
                className="md:hidden ml-4 text-white"
                onClick={toggleMobileMenu}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>

              {/* Mobile Menu */}
              {/* Mobile Menu (only displayed when isMobileMenuOpen is true) */}
              {window.innerWidth <= 768 && isMobileMenuOpen && (
                <ul className="flex lg:flex-row md:flex-row sm:text-center content-center flex-col items-center justify-center">
                  <li>
                    <a className="ml-4">
                      <Link
                        className="hover:text-indigo-400 text-center"
                        to="/"
                      >
                        Home
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a className="ml-4">
                      <Link
                        className="hover:text-indigo-400 text-center"
                        to="/courses"
                      >
                        Courses
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a className="ml-4">
                      <Link
                        className="hover:text-indigo-400 text-center"
                        to="/job-portal"
                      >
                        Placements
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a className="ml-4">
                      <Link
                        className="hover:text-indigo-400 text-center"
                        to="/bqforum"
                      >
                        Forum
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a className="ml-4">
                      <Link
                        className="hover:text-indigo-400 text-center"
                        to="/masterclasses"
                      >
                        Masterclass
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a className="ml-4">
                      <Link
                        className="hover:text-indigo-400 text-center"
                        to="/blogpage2"
                      >
                        Blog
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a className="ml-4">
                      <Link
                        className="hover:text-indigo-400 text-center"
                        to="/softwares"
                      >
                        Solutions
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a className="ml-4">
                      <Link
                        className="hover:text-indigo-400 text-center"
                        to="/dataDownloader"
                      >
                        Data Downloader
                      </Link>
                    </a>
                  </li>

                  <li>
                    <a className="ml-4">
                      <Link
                        className="hover:text-indigo-400 text-center"
                        to="/fundamentalData"
                      >
                        Ticker Fundamental Data
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a className="ml-4">
                      <Link
                        className="hover:text-indigo-400 text-center"
                        to="/macroeconomicsDashboard"
                      >
                        Macroeconomics Data
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a className="ml-4">
                      <Link
                        className="hover:text-indigo-400 text-center"
                        to="/microeconomicsDashboard"
                      >
                        Microeconomics Data
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a className="ml-4">
                      <Link
                        className="hover:text-indigo-400 text-center"
                        to="/bq-workshop"
                      >
                        Workshop
                      </Link>
                    </a>
                  </li>

                  {/* <li>
                    <a className="ml-4">
                      <Link
                        className="hover:text-indigo-400 text-center"
                        to="/aboutus"
                      >
                        About Us
                      </Link>
                    </a>
                  </li> */}
                  {App.adminUser && App.isLogin && (
                    <li>
                      <a className="ml-4">
                        <Link
                          className="hover:text-indigo-400 text-center"
                          to="/bqadmin/*"
                        >
                          Admin
                        </Link>
                      </a>
                    </li>
                  )}
                  {/* <li><a className='ml-4 hover:text-indigo-400 text-center' href="https://gauravkumarquant.herokuapp.com/Trade_simulator">Market Tools</a></li> */}
                  {/* <li><a className='ml-4 hover:text-indigo-400 text-center' href="/">Path To Quant</a></li> */}
                </ul>
              )}

              {/* Desktop Menu */}

              {window.innerWidth > 768 && (
                <ul className="lg:flex md:flex sm:text-center md:flex-row content-center flex-col items-center justify-center">
                  <li>
                    <a className="ml-4">
                      <Link
                        className="hover:text-indigo-400 text-center"
                        to="/"
                      >
                        Home
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a className="ml-4">
                      <Link
                        className="hover:text-indigo-400 text-center"
                        to="/courses"
                      >
                        Courses
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a className="ml-4">
                      <Link
                        className="hover:text-indigo-400 text-center"
                        to="/job-portal"
                      >
                        Placements
                      </Link>
                    </a>
                  </li>

                  <li className="ml-4 relative">
                    <button
                      className="hover:text-indigo-400 text-lg focus:outline-none"
                      onMouseEnter={() => setIsDropdownOpen(true)}
                      onMouseLeave={() => setIsDropdownOpen(false)}
                    >
                      Resources
                    </button>

                    {/* Dropdown Menu */}
                    {isDropdownOpen && (
                      <div
                        className="absolute left-0 bg-white z-20 text-black shadow-md rounded w-48"
                        onMouseEnter={() => setIsDropdownOpen(true)}
                        onMouseLeave={() => setIsDropdownOpen(false)}
                      >
                        <ul className="py-2 font-sans text-base">
                          <Link
                            className="hover:text-indigo-400 text-left"
                            to="/masterclasses"
                          >
                            <li className="px-4 py-2 hover:bg-indigo-100 transition-colors duration-300">
                              Masterclasses
                            </li>
                          </Link>
                          <Link
                            className="hover:text-indigo-400 text-left"
                            to="/blogpage2"
                          >
                            <li className="px-4 py-2 hover:bg-indigo-100 transition-colors duration-300">
                              Blog
                            </li>
                          </Link>
                          <Link
                            className="hover:text-indigo-400 text-left"
                            to="/bqforum"
                          >
                            <li className="px-4 py-2 hover:bg-indigo-100 transition-colors duration-300">
                              Forum
                            </li>
                          </Link>
                          <Link
                            className="hover:text-indigo-400 text-left"
                            to="/softwares"
                          >
                            <li className="px-4 py-2 hover:bg-indigo-100 transition-colors duration-300">
                              Solutions
                            </li>
                          </Link>
                        </ul>
                      </div>
                    )}
                  </li>

                  <li className="ml-4 relative">
                    <button
                      className="hover:text-indigo-400 text-lg focus:outline-none"
                      onMouseEnter={() => setIsDropdownOpen2(true)}
                      onMouseLeave={() => setIsDropdownOpen2(false)}
                    >
                      Research
                    </button>

                    {/* Dropdown Menu */}
                    {isDropdownOpen2 && (
                      <div
                        className="absolute left-0 bg-white z-20 text-black shadow-md rounded w-48"
                        onMouseEnter={() => setIsDropdownOpen2(true)}
                        onMouseLeave={() => setIsDropdownOpen2(false)}
                      >
                        <ul className="py-2 font-sans text-base">
                          <Link
                            className="hover:text-indigo-400 text-left"
                            to="/dataDownloader"
                          >
                            <li className="px-4 py-1 hover:bg-indigo-100 transition-colors duration-300">
                              Data Downloader
                            </li>
                          </Link>
                          <Link
                            className="hover:text-indigo-400 text-left"
                            to="/fundamentalData"
                          >
                            <li className="px-4 py-1 hover:bg-indigo-100 transition-colors duration-300">
                              Ticker Fundamental Data
                            </li>
                          </Link>
                          <Link
                            className="hover:text-indigo-400 text-left"
                            to="/macroeconomicsDashboard"
                          >
                            <li className="px-4 py-1 hover:bg-indigo-100 transition-colors duration-300">
                              Macroeconomics Data
                            </li>
                          </Link>
                          <Link
                            className="hover:text-indigo-400 text-left"
                            to="/microeconomicsDashboard"
                          >
                            <li className="px-4 py-1 hover:bg-indigo-100 transition-colors duration-300">
                              Microeconomics Data
                            </li>
                          </Link>
                        </ul>
                      </div>
                    )}
                  </li>
                  <li>
                    <a className="ml-4">
                      <Link
                        className="hover:text-indigo-400 text-center"
                        to="/bq-workshops"
                      >
                        Workshops
                      </Link>
                    </a>
                  </li>

                  {/* <li>
                    <a className="ml-4">
                      <Link
                        className="hover:text-indigo-400 text-center"
                        to="/aboutus"
                      >
                        About Us
                      </Link>
                    </a>
                  </li> */}
                  {App.adminUser && App.isLogin && (
                    <li>
                      <a className="ml-4">
                        <Link
                          className="hover:text-indigo-400 text-center"
                          to="/bqadmin/*"
                        >
                          Admin
                        </Link>
                      </a>
                    </li>
                  )}
                  {/* <li><a className='ml-4 hover:text-indigo-400 text-center' href="https://gauravkumarquant.herokuapp.com/Trade_simulator">Market Tools</a></li> */}
                  {/* <li><a className='ml-4 hover:text-indigo-400 text-center' href="/">Path To Quant</a></li> */}
                </ul>
              )}

              <div className="text-5xl font-thin ml-2">|</div>
              <div className="ml-2 flex md:flex-row flex-col items-center justify-center text-center">
                {!App.isLogin ? (
                  <div className="p-1">
                    <button className="text-white bg-green-600 hover:bg-green-800 font-bold py-2 px-2 rounded-lg">
                      <a className="hover:text-white">
                        <Link className="hover:text-white" to="/login">
                          Login
                        </Link>
                      </a>
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col sm:flex-row justify-center items-center text-center">
                      <button
                        className=" ml-4 text-white"
                        onClick={handleMobileMenuOpen}
                      >
                        <Box
                          bgcolor="#4CAF50" // Set the background color to green
                          className="hover:bg-green-700"
                          borderRadius="20%" // Make it a circle
                          p={1} // Add some padding
                          display="inline-flex" // Make sure it's an inline element
                        >
                          <AccountCircleSharpIcon fontSize="medium" />
                        </Box>
                      </button>
                      <span
                        onClick={() => navigate("/dashboard")}
                        className="pl-1 font-thin text-xs md:text-sm text-center cursor-pointer text-gray-300 hover:text-gray-100"
                      >
                        Dashboard
                      </span>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMobileMenuClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <div className="pl-2 py-3 text-sm text-gray-900 dark:text-white overflow-hidden">
                          <div>{name}</div>
                          <div
                            className="font-medium truncate"
                            style={{
                              width: "150px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {email}
                          </div>
                        </div>
                        <Divider />
                        <div className="py-2">
                          <MenuItem
                            sx={{ fontSize: "body2.fontSize" }}
                            onClick={() => handleMenuItemClick("/dashboard")}
                          >
                            Dashboard
                          </MenuItem>
                          <MenuItem
                            sx={{ fontSize: "body2.fontSize" }}
                            onClick={() => handleMenuItemClick("/userprofile")}
                          >
                            Account Settings
                          </MenuItem>
                          <Divider />
                          <MenuItem
                            sx={{ fontSize: "body2.fontSize" }}
                            className="pt-2"
                            onClick={handleLogout}
                          >
                            Sign Out
                          </MenuItem>
                        </div>
                      </Menu>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;

import "./App.css";
import "./index.css";

import { Route, Routes, useNavigate } from "react-router-dom";
import { createContext, useEffect, useState, lazy, Suspense } from "react";
import { Global, css } from "@emotion/react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";

import { auth } from "./Firebase/firebase";
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import NotFound from "./Components/NotFound";
import ScrollWrapper from "./Components/ScrollWrapper";

// Lazy load components
const AboutUs = lazy(() => import("./Components/AboutUs"));
const Courses = lazy(() => import("./Components/Courses"));
const Main = lazy(() => import("./Components/Main"));
const Softwares = lazy(() => import("./Components/Softwares"));
const Contact = lazy(() => import("./Components/Contact"));
const Faq = lazy(() => import("./Components/Faq"));
const Login = lazy(() => import("./Components/Login"));
const Signup = lazy(() => import("./Components/Signup"));
const TermsNConditions = lazy(() => import("./Components/TermsNConditions"));
const BlogPage2 = lazy(() => import("./Components/BlogPage2"));
const ScheduleMeeting = lazy(() => import("./Components/ScheduleMeeting"));
const QuantitativeFinance = lazy(() =>
  import("./Components/QuantitativeFinance - py")
);
const QuantitativeFinanceExcel = lazy(() =>
  import("./Components/QuantitativeFinanceExcel")
);
const BlockchainTechnology = lazy(() =>
  import("./Components/BlockchainTechnology")
);
const AutomationFinanceConsultation = lazy(() =>
  import("./Components/AutomationFinanceConsultation")
);
const Consultation = lazy(() => import("./Components/Consultation"));
const Masterclasses = lazy(() => import("./Components/Masterclasses"));
const FullVideo = lazy(() => import("./Components/FullVideo"));
const EnrollMasterclass = lazy(() => import("./Components/EnrollMasterclass"));
const Dashboard = lazy(() => import("./Components/Usersection/Dashboard"));
const QfbypythonCoursepage = lazy(() =>
  import("./Components/Usersection/QfbypythonCoursepage")
);
const UserProfile = lazy(() => import("./Components/Usersection/UserProfile"));
const Lecturehost = lazy(() =>
  import("./Components/Lectures/LectureHost/Lecturehost")
);
const LectureSetup = lazy(() =>
  import("./Components/Lectures/LectureHost/LectureSetup")
);
const Viewer = lazy(() => import("./Components/Lectures/LectureHost/Viewer"));
const MyCourses = lazy(() => import("./Components/Usersection/MyCourses"));
const AccountTransactions = lazy(() =>
  import("./Components/Usersection/AccountTransactions")
);
const BqAdmin = lazy(() => import("./Components/Admin/BqAdmin"));
const BannerControl = lazy(() => import("./Components/Admin/BannerControl"));
const PythonforQuantFinance = lazy(() =>
  import("./Components/coursedetails/PythonforQuantFinance")
);
const ForumPage = lazy(() =>
  import("./Components/Usersection/Forum/ForumPage")
);
const PostPage = lazy(() => import("./Components/Usersection/Forum/PostPage"));
const MyForumPosts = lazy(() =>
  import("./Components/Usersection/Forum/MyForumPosts")
);
const CreateBlog = lazy(() => import("./Components/CreateBlog"));
const BlogDetail = lazy(() => import("./Components/BlogDetail"));
const MyBlogList = lazy(() => import("./Components/MyBlog/MyBlogList"));
const MyPostPage = lazy(() => import("./Components/MyBlog/MyPostPage"));
const DataDownloader = lazy(() =>
  import("./Components/YahooFinanceAndOtherData/DataDownloader")
);
const FundamentalData = lazy(() =>
  import("./Components/YahooFinanceAndOtherData/FundamentalData")
);
const MacroeconomicsDashboard = lazy(() =>
  import("./Components/YahooFinanceAndOtherData/MacroeconomicsDashboard")
);
const MicroeconomicsDashboard = lazy(() =>
  import("./Components/YahooFinanceAndOtherData/MicroeconomicsData")
);

const PortfolioOptimizer = lazy(() =>
  import("./Components/PortfolioOptimization/PortfolioOptimizer")
);
const JobPortal = lazy(() => import("./Components/JobsAndCareers/JobPortal"));
const JobDetails = lazy(() => import("./Components/JobsAndCareers/JobDetails"));

const TestSeries = lazy(() => import("./Components/MCQTest/TestSeries"));
const MCQTest = lazy(() => import("./Components/MCQTest/MCQTest"));
const PaidMCQTest = lazy(() => import("./Components/MCQTest/PaidMCQTest"));
const ResultPage = lazy(() => import("./Components/MCQTest/ResultPage"));

const IntroToCareerinFinance = lazy(() =>
  import("./Components/Workshops/IntroToCareerinFinance")
);

const AppState = createContext();

const MeetingContext = createContext();

function App() {
  // const navigate = useNavigate();
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");

      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  });

  const [isLogin, setIsLogin] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [purchased, setPurchased] = useState(null);
  const [adminUser, setAdminUser] = useState(false);

  // Live Lecture data
  const [appData, setAppData] = useState({ meetingId: null, mode: null });

  const [meetingId, setMeetingId] = useState(null);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Check if the user has a display name (which means they have logged in, not just signed up)
        if (user.displayName) {
          setIsLogin(true);
          setUserName(user.displayName);
          setUserEmail(user.email);

          user.getIdTokenResult().then((idTokenResult) => {
            if (idTokenResult.claims.admin) {
              setAdminUser(true);
            } else {
              setAdminUser(false);
            }
          });

          const docRef = doc(getFirestore(), "userprofile", user.uid);
          getDoc(docRef)
            .then((docSnapshot) => {
              if (docSnapshot.exists()) {
                const userData = docSnapshot.data();
                const hasPurchased = userData.hasPurchased || false;
                setPurchased(hasPurchased);
              }
            })
            .catch((error) => {
              console.error("Error fetching user data from Firestore:", error);
            });

          // console.log(user);
        } else {
          setIsLogin(false);
          setUserName(null);
          setUserEmail(null);
        }
      } else {
        setIsLogin(false);
        setUserName(null);
        setUserEmail(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <AppState.Provider
      value={{
        isLogin,
        setIsLogin,
        userName,
        setUserName,
        userEmail,
        setUserEmail,
        purchased,
        setPurchased,
        adminUser,
        setAdminUser,
      }}
    >
      <MeetingContext.Provider value={{ meetingId, setMeetingId }}>
        <div className=" relative app min-h-screen">
          <Global
            styles={css`
              body {
                margin: 0;
              }
            `}
          />
          <NavBar name={userName} email={userEmail} />
          <Suspense fallback={<div className="min-h-screen">Loading...</div>}>
            <ScrollWrapper>
              <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/courses" element={<Courses />} />
                <Route path="/masterclasses" element={<Masterclasses />} />
                <Route
                  path="/masterclasses/enrollmasterclass"
                  element={<EnrollMasterclass />}
                />
                <Route path="/video/:videoId" element={<FullVideo />} />
                {isLogin && (
                  <>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route
                      path="/userprofile"
                      element={
                        <UserProfile name={userName} email={userEmail} />
                      }
                    />

                    <Route
                      path="/accounttransactions"
                      element={<AccountTransactions />}
                    />
                    {/* Forum Page My Posts */}
                    <Route
                      path="/bqforum/myforumposts/:id"
                      element={<MyForumPosts />}
                    />
                  </>
                )}

                {/* Forum Section Pages  */}
                <Route path="/bqforum" element={<ForumPage />} />
                <Route
                  path="/bqforum/postpage/:id/:title"
                  element={<PostPage />}
                />

                {isLogin && purchased && (
                  <>
                    <Route path="/mycourses" element={<MyCourses />} />

                    <Route
                      path="/qfpcoursepage"
                      element={<QfbypythonCoursepage />}
                    />
                  </>
                )}
                {/* Live Lecture Component */}
                {/* {appData.meetingId ? (
            appData.mode === "CONFERENCE" ? (
              <Route path='/speaker' element={<SpeakerScreenContainer meetingId={appData.meetingId} />} />
            ) : (
              <Route path="/viewer" element={<ViewerScreenContainer meetingId={appData.meetingId} />} />
            )
          ) : (
            <Route path='/createLectureSetup' element={<WelcomeScreenContainer setAppData={setAppData} />} />
          )} */}
                {/* <Route path='/createLectureSetup' element={<WelcomeScreenContainer setAppData={setAppData} appData={appData} />} /> */}
                {/* Live Lecture Interface Route */}
                {/* <Route path='/lecturehost' element={<Lecturehost />} /> */}

                {/* <Route path='/lecturesetup' element={<LectureSetup />} /> */}
                {/* <Route path='/playvideo' element={<Viewer />} /> */}

                {/* <Route path = '/sidebar' element={<Sidebarcomponent/>} /> */}
                <Route
                  path="/qfbypython"
                  element={
                    <QuantitativeFinance name={userName} email={userEmail} />
                  }
                />
                <Route
                  path="/qfbyexcel"
                  element={
                    <QuantitativeFinanceExcel
                      name={userName}
                      email={userEmail}
                    />
                  }
                />
                <Route
                  path="/blockchaintechnology"
                  element={
                    <BlockchainTechnology name={userName} email={userEmail} />
                  }
                />

                {/* New Detailed CoursePage */}
                <Route
                  path="/pythonforquantitativefinance"
                  element={<PythonforQuantFinance />}
                />

                <Route path="/softwares" element={<Softwares />} />
                <Route path="/schedulemeeting" element={<ScheduleMeeting />} />

                {/* Yahoo Finance and otehr economic Data Analysis */}

                <Route path="/dataDownloader" element={<DataDownloader />} />
                <Route path="/fundamentalData" element={<FundamentalData />} />
                <Route
                  path="/macroeconomicsDashboard"
                  element={<MacroeconomicsDashboard />}
                />
                <Route
                  path="/microeconomicsDashboard"
                  element={<MicroeconomicsDashboard />}
                />
                <Route
                  path="/portfolioOptimizer"
                  element={<PortfolioOptimizer />}
                />

                <Route path="/blogpage2" element={<BlogPage2 />} />
                <Route path="/create-blog" element={<CreateBlog />} />
                <Route path="/blog-post/:id/:title" element={<BlogDetail />} />

                {/* Workshops */}
                <Route
                  path="/bq-workshops"
                  element={<IntroToCareerinFinance />}
                />

                {isLogin && (
                  <>
                    <Route
                      path="/myblogpage/:userId"
                      element={<MyBlogList />}
                    />
                    <Route
                      path="/mypost-page/:userId/:id"
                      element={<MyPostPage />}
                    />
                  </>
                )}

                <Route path="/job-portal" element={<JobPortal />} />
                <Route path="/jobdetail/:jobId" element={<JobDetails />} />

                {/* Student Exam */}

                {isLogin && (
                  <>
                    <Route path="/testseries" element={<TestSeries />} />
                    <Route path="/mcqtest/:sheetId" element={<MCQTest />} />
                  </>
                )}
                <Route
                  path="/bqTestresult/:sheetId/:userId/:resultId"
                  element={<ResultPage />}
                />

                {isLogin && purchased && (
                  <>
                    <Route
                      path="/mcqtest_paid/:sheetId"
                      element={<PaidMCQTest />}
                    />
                  </>
                )}

                <Route path="/contact" element={<Contact />} />
                <Route path="/faq" element={<Faq />} />

                <Route path="/tnc" element={<TermsNConditions />} />
                <Route
                  path="/autofinconsultation"
                  element={<AutomationFinanceConsultation />}
                />
                <Route path="/consultation" element={<Consultation />} />

                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="*" element={<NotFound />} />

                {/* Admin */}
                <Route path="/bqadmin/*" element={<BqAdmin />}>
                  <Route path="bannercontrol" element={<BannerControl />} />
                </Route>
              </Routes>
            </ScrollWrapper>
          </Suspense>
          <Footer />
        </div>
      </MeetingContext.Provider>
    </AppState.Provider>
  );
}

export default App;
export { AppState, MeetingContext };
